import { DependencyList, useEffect } from "react";
import { useStore } from "react-redux";
import { Store } from "redux";
import { useCommonSelector } from "../redux/commonStore";
import { CommonSettingsType } from "../settings/fetchCommonSettings";
import { getAPI } from "../settings/utils/commonSettingUtils";
import { DataEndpointBodyType, DataEndpointReturnType } from "../server/v2/endpoints/DataEndpoint";
import Debug from "../Debug";
import { Fetch, PostFetchFnType, FetchDispatcherType, GetFetchesFnType } from "../utils/registryTypes";
import { ComponentType } from "../../shared-logic/server/components";
import { commonPostFetch } from "../server/v2/utils/commonPostFetch";
import { QueryType } from "../server/types";
import { addErrorLog, addRequestLog } from "../redux/actions/UtilActions";
import { ErrorLogType } from "../types/CommonTypes";
import { RequestLogType } from "../utils/backendFetch";

type StateBaseType = { commonSettings: CommonSettingsType };

// TODO We could consider wrapping this with a registry for easier and more consistent usage.
export const executeFrontendDataFetch = async <S extends Store, F extends Fetch>(
    store: S,
    getFetches: GetFetchesFnType,
    fetchDispatcher: FetchDispatcherType<F>,
    commonSettings: CommonSettingsType,
    component: ComponentType, // Although this is available in commonSettings, we need this value manually for cases where another component is integrated.
    propagatedParams: (keyof QueryType)[], // Set of query/body params which will be propagated to the data endpoint.
    postFetch?: PostFetchFnType<S>,
): Promise<void> => {
    const { dispatch } = store;

    let count = 0;
    const executeFetch = async (): Promise<void> => {
        count++;
        if (count >= 10) {
            // Infinite loop failsafe.
            Debug.error("Data fetch loop detected");
            return;
        }
        // Get most recent state, calculate fetches.
        // ⚠️ Do not use state like this in other hooks or components, this is specifically done for data fetching ⚠️
        const state = store.getState();
        const fetches = getFetches(state);

        if (fetches.length) {
            // Fetches available, go to server and fetch.
            // Gather additional params to be added to the request if required.
            const body: DataEndpointBodyType = { component, fetches };
            const query: string[] = [];
            if (propagatedParams.length) {
                Object.entries(commonSettings.body as Record<string, any>).forEach(([key, value]) => {
                    if (propagatedParams.includes(key as keyof QueryType)) body[key] = value;
                });

                Object.entries(commonSettings.query as Record<string, any>).forEach(([key, value]) => {
                    if (propagatedParams.includes(key as keyof QueryType)) query.push(`${key}=${value as string}`);
                });
            }

            let url = getAPI(commonSettings, "data");
            if (query.length) url += `?${query.join("&")}`;

            const result = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body),
            });
            const parsedResult: DataEndpointReturnType = await result.json();

            // In addition to Fetch add the optional reqLog and errorLog arrays which are commonly used.
            // At some point we might be able to add these to Fetch type by default but there is currently too many variances across OR.
            type ResultType = Required<F> & {
                result?: { [extraProps: string]: unknown; reqLogs?: RequestLogType[]; errorLogs?: ErrorLogType[] };
            };
            // Fetches received, set them in state.
            parsedResult.fetches.forEach((fetch: ResultType) => {
                fetchDispatcher(fetch, dispatch);

                if (fetch?.result?.reqLogs?.length) store.dispatch(addRequestLog(fetch.result?.reqLogs));
                if (fetch?.result?.errorLogs?.length) store.dispatch(addErrorLog(fetch.result?.errorLogs));
            });

            // Start the next loop.
            await executeFetch();
        } else {
            if (postFetch) {
                // Fetch loop is done, trigger postFetch if available.
                await postFetch(store);
            }
            commonPostFetch(store);
        }
    };
    try {
        await executeFetch();
    } catch (ex) {
        Debug.error("Failed to execute fetch loop", ex);
    }
};

/**
 * This hook can be used to fetch data usually retrieved during SSR.
 * The fetch fns used here should be the exact same ones used in the component registry.
 * Current setup is based on ORv2, v1 is not supported for now.
 *
 * @param getFetches - Component fetch function
 * @param fetchDispatcher - Component Fetch dispatcher function
 * @param deps - Hook dependencies, when the dep changes the fetchloop will be ran again. use sparingly.
 * @param postFetch - Optional component postFetch function
 */
const useDataFetcher = <S extends StateBaseType, F extends Fetch>(
    getFetches: GetFetchesFnType,
    fetchDispatcher: FetchDispatcherType<F>,
    component: ComponentType,
    deps: DependencyList = [],
    propagatedParams: (keyof QueryType)[],
    postFetch?: PostFetchFnType<Store<S>>,
): void => {
    const store = useStore<S>();
    const commonSettings = useCommonSelector((state) => state.commonSettings);

    useEffect(() => {
        executeFrontendDataFetch(
            store,
            getFetches,
            fetchDispatcher,
            commonSettings,
            component,
            propagatedParams,
            postFetch,
        );
    }, deps);
};

export default useDataFetcher;
