import { useState, useEffect } from "react";
import { useStore } from "react-redux";
import { executeFrontendDataFetch } from "../../../common-deprecated/hooks/useDataFetcher";
import { useCommonSelector } from "../../../common-deprecated/redux/commonStore";
import { GetFetchesFnType } from "../../../common-deprecated/utils/registryTypes";
import { COMPONENT_COMPARE_USC } from "../../../shared-logic/server/components";
import { CompareUSCStateType, CompareUSCActionsType, compareUSCReducers } from "../redux/store";
import { compareUSCFetchDispatcher, getCompareUSCComponentFetches } from "../datafetch/fetchUtils";
import usedCarCompareLogic from "../redux/logic/UsedCarCompareLogic";

const useIntegratedUsedCompare = (): { fetching: boolean } => {
    const store = useStore<CompareUSCStateType, CompareUSCActionsType>();
    const commonSettings = useCommonSelector((state) => state.commonSettings);

    const [fetching, setFetching] = useState<boolean>(true);

    useEffect(() => {
        store.addReducers(compareUSCReducers);

        const fetchData = async (): Promise<void> => {
            await executeFrontendDataFetch(
                store,
                getCompareUSCComponentFetches as unknown as GetFetchesFnType,
                compareUSCFetchDispatcher,
                commonSettings,
                COMPONENT_COMPARE_USC,
                ["brand", "uscEnv"],
            );

            setFetching(false);

            store.logicMiddleware?.mergeNewLogic(usedCarCompareLogic);
        };

        fetchData();
    }, []);

    return { fetching };
};

export default useIntegratedUsedCompare;
