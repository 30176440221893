import { SyntheticEvent } from "react";
import { getCompareUSCLabel } from "./labels";
import { CompareUscSettingsType } from "../redux/reducers/CompareUscSettingsReducer";
import { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";
import { CompareUSCDispatchType } from "../redux/store";
import {
    LoadedUsedCompareCarType,
    UsedCompareCarType,
} from "../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import { getUscVehicleUrlInfoFromCompareResult } from "./usedSpecsUtils";
import { getUscUrl } from "../../shared-logic/utils/uscUtils";
import { showModal } from "../../../common-deprecated/redux/actions/ModalActions";
import { DealerResultType } from "../../../common-deprecated/utils/dealerConstants";
import { runLocalContactDealerClick } from "../../shared-logic/utils/localDealerUtils";
import { LocalDealerFormDataType } from "../../shared-logic/types/UscCommonTypes";

import { getUscCashPriceFromVehicle } from "../../shared-logic/utils/uscPriceUtils";
import { MODAL_USC_FORM } from "../../shared-logic/utils/integrated-modal/integratedModalConstants";
import { DfVehicleStatus } from "../../shared-logic/utils/dfConstants";

export const getLocalDealerFormData = (
    vehicle: LoadedUsedCompareCarType,
    vehicleUrl: string,
    dealer: DealerResultType | null,
): LocalDealerFormDataType => {
    return {
        vehicleForSaleId: vehicle.id,
        brand: vehicle.product.brand.description,
        model: vehicle.product.model,
        image: vehicle.images[0]?.url || "",
        grade: vehicle.product.grade?.gradeDescription || "",
        engine: vehicle.product.engine.description || "",
        cashPrice: getUscCashPriceFromVehicle(vehicle).cashPriceInclVat || 0,
        fuelType: vehicle.product.engine.fuelType.description,
        mileage: vehicle.mileage?.value || 0,
        firstRegistrationDate: vehicle.history?.registrationDate || "",
        vin: vehicle.vin,
        dealerName: dealer?.name || "",
        dealerAddress: dealer ? `${dealer.address.line1} ${dealer.address.zip} ${dealer.address.city}` : "",
        url: vehicleUrl,
        t1DataLayerModel: "",
        tyCode: "",
        financeQuoteId: "",
        insuranceQuoteId: "",
    };
};

/**
 * Opens the Contact Dealer form
 */
export const openContactDealerForm = (
    vehicle: UsedCompareCarType,
    detailPageUrl: string,
    context: "used" | "stock",
    commonSettings: CommonSettingsType,
    dispatch: CompareUSCDispatchType,
    modalTitle: string,
): void => {
    const { loading } = vehicle;

    if (loading) return;

    const urlInfo = getUscVehicleUrlInfoFromCompareResult(vehicle);
    const vehicleUrl = getUscUrl(commonSettings, detailPageUrl, "used", urlInfo);

    // modalLogic will attach additional vehicle/dealer/finance/... information to this event so we dont have to propagate it.
    dispatch(
        showModal({
            type: MODAL_USC_FORM,
            vehicleForSaleId: vehicle.id,
            uscContext: context,
            modalTitle,
            externalData: {
                externalPriceInfo: {
                    financeInfo: vehicle.financeInfo,
                    cashPrice: getUscCashPriceFromVehicle(vehicle),
                    monthlyPrice: vehicle.monthlyPrice,
                },
                vehicleUrl,
            },
        }),
    );
};

/**
 * Returns a Contact Dealer CTA to be used on the Car Compare
 * This version returns a more limited object compared to the used-stock-cars getContactDealerCta containing only the label of the
 * button & the onClick event. This is mainly due to not needing the other data
 * at this time
 */
export const getContactDealerCta = (
    vehicle: UsedCompareCarType,
    detailPageUrl: string,
    commonSettings: CommonSettingsType,
    compareUscSettings: CompareUscSettingsType,
    dealer: DealerResultType | null,
    dispatch: CompareUSCDispatchType,
    tracking: () => void,
): {
    click: ((event: SyntheticEvent) => void) | null;
    label: string;
} | null => {
    // Don't show the CTA if the vehicle is sold or there is no dealer,
    // as the user should not be able to contact the dealer
    if ((!vehicle.loading && vehicle.vehicleStatus.code === DfVehicleStatus.Sold) || !dealer) return null;

    const { localContactDealerFormUrl } = compareUscSettings;

    let contactDealerclick: ((event: SyntheticEvent) => void) | null = null;

    const label = getCompareUSCLabel({ compareUscSettings, commonSettings }, "usedCompareButtonAppointment");
    if (localContactDealerFormUrl && !commonSettings.query.disableLocalContactForm) {
        contactDealerclick = () => {
            if (!vehicle || vehicle.loading) return;

            runLocalContactDealerClick({
                url: localContactDealerFormUrl,
                commonSettings,
                context: "used",
                detailPageUrl: compareUscSettings.urlUsedCarsDetailPage,
                vehicleUrlInfo: getUscVehicleUrlInfoFromCompareResult(vehicle),
                dealer,
                localDealerFormatter: getLocalDealerFormData,
                tracking,
                vehicle,
            });
        };
    } else if (vehicle) {
        const modalTitle = getCompareUSCLabel(
            { compareUscSettings, commonSettings },
            "usedCompareContactDealerModalTitle",
        );
        contactDealerclick = () => {
            tracking();
            openContactDealerForm(vehicle, detailPageUrl, "used", commonSettings, dispatch, modalTitle);
        };
    }

    return {
        click: contactDealerclick,
        label,
    };
};
