import { openMaterialBox } from "../../../common-deprecated/utils/postalUtils";
import { LoadedUsedCompareCarType } from "../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import { VehicleType, ContactDealerClickOptions } from "../types/UscCommonTypes";
import { getUscUrl } from "./uscUtils";

export const runLocalContactDealerClick = <T extends VehicleType | LoadedUsedCompareCarType>(
    params: ContactDealerClickOptions<T>,
): void => {
    const { commonSettings, vehicle, detailPageUrl, context, vehicleUrlInfo, localDealerFormatter, tracking, url } =
        params;
    const { dealer, insuranceQuoteId, financeQuoteId, tyCode } = params;

    let iframeUrl = url;
    if (vehicle) {
        // Make sure getUscUrl is called within the function handler as it parses T1 window object data which tends to not be activated during init.
        const vehicleUrl = getUscUrl(commonSettings, detailPageUrl, context, vehicleUrlInfo);

        // Get the local form data and parse into url parameters.
        const localFormData = localDealerFormatter(
            vehicle,
            vehicleUrl,
            dealer,
            insuranceQuoteId,
            financeQuoteId,
            tyCode,
        );
        const urlParams = (Object.keys(localFormData) as (keyof ReturnType<typeof localDealerFormatter>)[])
            .filter((key) => !!localFormData[key])
            .map((key) => `${String(key)}=${String(localFormData[key])}`)
            .join("&");
        iframeUrl += `?${encodeURI(urlParams)}`;

        // Consider using useT1ModalInjection if any other local form implementations are necessary.
    }
    tracking();
    // Open into an iframe, as this should be an edge case scenario keep the config here for now.
    // Height/width is based on modal dimensions that are usually used.
    openMaterialBox({
        url: iframeUrl,
        type: "box-iframe",
        height: window.innerHeight - 125,
        width: Math.min(window.innerWidth, 992),
    });
};
